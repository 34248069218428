import url from 'url';
import { Storage } from 'aws-amplify';
import { split } from 'lodash';
import { SourceLocator } from '@amzn/fox-den-cost-planning-lambda';
// import { envConfig } from 'configuration/aws-exports';
export const S3_COST_PLANNING_BUCKET = 'fox-cost-planning-beta';
export const S3_COST_PLANNING_BUCKET_WRITE_ONLY = 'fox-cost-planning-write-only-beta';
export const S3_PLANET_BUCKET_WRITE_ONLY = 'planet-write-only-beta-us-east-1';

export interface IS3DataLocator {
  bucket: string;
  key: string | null;
}

export const parseS3UriToLocator = (s3Uri: string) => {
  const uri = url.parse(s3Uri, true);
  if (uri.protocol !== 's3:') {
    throw new Error(`Invalid S3 URI: ${s3Uri}`);
  }
  const bucket = uri.host;
  if (!bucket) {
    throw new Error(`Invalid S3 URI with no bucket: ${s3Uri}`);
  }
  let key: string | null = null;
  if (!(!uri.pathname || uri.pathname.length <= 1)) {
    // not s3://bucket or s3://bucket/
    // Remove the leading '/'.
    key = uri.pathname.substring(1);
  }
  if (key !== null) {
    key = decodeURIComponent(key);
  }
  return {
    bucket,
    key,
  };
};

/**
 * Config amplify region for upload file
 */
export const configRegion = (bucket: string) => {
  const region =
    bucket === S3_COST_PLANNING_BUCKET || bucket === S3_COST_PLANNING_BUCKET_WRITE_ONLY
      ? 'us-west-2'
      : 'us-east-1';

  Storage.configure({ region });
};

/**
 * Config amplify Storage object for upload file
 */
export const configStorage = (pathPrefix: string, bucket: string) => {
  const s3Config = {
    bucket,
    customS3Prefix: {
      public: pathPrefix,
    },
    level: 'public',
  };
  Storage.configure({
    bucket: s3Config.bucket,
    customPrefix: s3Config.customS3Prefix,
    level: 'public',
  });
};

export const getDownloadInfo = (key: string) => {
  const tokens = split(key, '/');
  const url = split(key, '/', tokens.length - 1).join('/') + '/';
  const fileName = tokens[tokens.length - 1];
  return { url, fileName };
};

export const downloadS3Data = async (
  dataLocator: IS3DataLocator,
  partitionLocator: SourceLocator | null | undefined,
) => {
  const bucket =
    partitionLocator === null || partitionLocator === undefined
      ? S3_COST_PLANNING_BUCKET
      : partitionLocator.bucket;
  if (!dataLocator.key) {
    return '';
  }
  const locatorInfo = getDownloadInfo(dataLocator.key);
  // We cannot use configStorage method. Otherwise, when downloading multiple datasets simultaneously, Storage may
  // use wrong config to download.
  configRegion(bucket);
  const result = await Storage.get(locatorInfo.fileName, {
    bucket,
    customPrefix: { public: locatorInfo.url },
    level: 'public',
  });
  const content = await fetch(result.toString());
  const data = await content.text();
  return data;
};

export const putInS3 = (
  fileName: string,
  fileData: File,
  fileType: string,
  progressCallback: ((progress: number) => void) | null = null,
) =>
  Storage.put(fileName, fileData, {
    contentType: fileType,
    progressCallback(progress: any) {
      progressCallback?.((progress.loaded / progress.total) * 100);
    },
  });
